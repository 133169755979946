<template>
    <!-- 运维管理》平台综合数据 -->
    <div id="app_operation_qxt_data">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">平台综合数据</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            
             <!-- 查询条件 -->
            <el-row id="query_condition_id" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" >
                    
                    <el-form-item label="">
                        <el-select v-model="form_data.type" size="mini" style="width:100%"> 
                            <el-option label="按日显示" value="day"></el-option>
                            <el-option label="按周显示" value="week"></el-option>
                            <el-option label="按月显示" value="month"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="" v-show="form_data.type=='week' || form_data.type=='day'">
                         <el-date-picker
                            v-model="form_data.startDate"
                            type="date"
                            placeholder="请选择开始日期" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>
                   
                    <el-form-item label="到" v-show="form_data.type=='week' || form_data.type=='day'">
                        <el-date-picker
                            v-model="form_data.endDate"
                            type="date"
                            placeholder="请选择结束日期" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>



                    <el-form-item label="" v-show="form_data.type=='month'">
                        <el-date-picker
                            v-model="form_data.startDate_month"
                            type="month"
                            placeholder="选择开始月份" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="到" v-show="form_data.type=='month'">
                        <el-date-picker
                            v-model="form_data.endDate_month"
                            type="month"
                            placeholder="选择结束月份" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>

                </el-form>
                
            </el-row>

            <!-- 体 -->
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-form_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" show-summary  :summary-method="getSummers" size="mini" >
                    <el-table-column  prop="daytime" label="日期" min-width="90" fixed align="center"> </el-table-column>
                    <el-table-column  prop="profit_total" label="总利润(元)" min-width="100" sortable align="center"> </el-table-column>
                    <el-table-column  prop="profit_average_one" label="平均单条利润(厘)" min-width="100"  align="center"> </el-table-column>
                    <el-table-column  prop="check_total" label="审核量" min-width="80" sortable align="center"> </el-table-column>
                    <el-table-column  prop="user_total" label="客户总发送量" min-width="100" sortable align="center"> </el-table-column>
                    <el-table-column  prop="cpid_success_num" label="客户成功数" min-width="100" sortable align="center"> </el-table-column>
                    <el-table-column  prop="cpid_fail_num" label="客户失败数" min-width="100" sortable align="center"> </el-table-column>
                    <el-table-column  prop="cpid_unknown_num" label="客户未知数" min-width="100" sortable align="center"> </el-table-column>
                    <el-table-column  prop="repeat_num" label="重发数" min-width="90" sortable align="center"> </el-table-column>

                    <el-table-column  prop="cpid_success_rate" label="客户成功率(%)" min-width="110" sortable align="center"> </el-table-column>
                    <el-table-column  prop="cpid_fail_rate" label="客户失败率(%)" min-width="110" sortable align="center"> </el-table-column>
                    <el-table-column  prop="cpid_unknown_rate" label="客户未知率(%)" min-width="110" sortable align="center"> </el-table-column>
                    <el-table-column  prop="repeat_rate" label="重发率(%)" min-width="100" sortable align="center"> </el-table-column>

                </el-table>

                <!-- 图表 -->
                <div id="image_table_id" style="width: 100%;height:400px;margin-top:0px;">
                </div>  
            </el-row>

        </el-row>

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import * as echarts from 'echarts';

//import axios from "axios";
export default {
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                type:"day",//查询条件-类型 day:日  week:周； month:月
                startDate:"",//查询条件-开始日期  类型为 day或week时使用
                endDate:"",//查询条件-结束日期  类型为 day或week时使用

                startDate_month:"",//查询条件-开始日期  类型为 month时使用
                endDate_month:"",//查询条件-结束日期  类型为 month时使用

            },
            tableData:[],//表数据
            
            form_height:40,//查询条件的高度

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        // this.companyList = this.$options.data().companyList;//单个恢复初始化
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
        var image_tabel_01 = document.getElementById("image_table_id");
        if(image_tabel_01){
            image_tabel_01.innerHTML = "";
        }

        //初始化设置
        this.initSetUp();
        //查询
        //this.submit_cha_xun();
       
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //获取分页的高度
                if(document.getElementById('query_condition_id')){
                    this.form_height = document.getElementById('query_condition_id').clientHeight;
                    console.log("form_height:"+this.form_height);
                }
            });

        },

       

        //查询
        submit_cha_xun(){


            var select_time = "";
            if(this.form_data.type == "day" || this.form_data.type == "week"){
                if(API.isEmtry(this.form_data.startDate)){
                    this.$message.error("请选择开始日期");
                    return;
                }
                if(API.isEmtry(this.form_data.endDate)){
                    this.$message.error("请选择结束日期");
                    return;
                }
               
                select_time = dateFtt(this.form_data.startDate,"yyyyMMdd") + "~" + dateFtt(this.form_data.endDate,"yyyyMMdd");
            }else{
                if(API.isEmtry(this.form_data.startDate_month)){
                    this.$message.error("请选择开始月份");
                    return;
                }
                if(API.isEmtry(this.form_data.endDate_month)){
                    this.$message.error("请选择结束月份");
                    return;
                }
                select_time = dateFtt(this.form_data.startDate_month,"yyyyMM") + "~" + dateFtt(this.form_data.endDate_month,"yyyyMM");
            }


            //请求接口
            API.OperationManagerServlet({
                param: "qxt_data_list",
                type:this.form_data.type,
                select_time:select_time,

            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.list;
                    
                    var xAxisData = [];
                    var dataList = [];
                    var titleList = ["总利润（元）","客户总发送量"
                    ,"客户成功数","客户失败数","客户未知数","重发数"
                    ,"审核量","平均单条利润(厘)"
                    ,"成功率%","失败率%","未知率%","重发率%"]; 

                    var one_list = [];
                    var two_list = [];
                    var three_list = [];
                    var four_list = [];
                    var five_list = [];
                    var six_list = [];
                    var seven_list = [];

                    var eight_list = [];
                    var nine_list = [];
                    var ten_list = [];
                    var eleven_list = [];
                    var twelve_list = [];

                    for(var i=0;i<this.tableData.length;i++){
                        var item = this.tableData[i];
                        var daytime = item.daytime;
                        xAxisData.push(daytime);

                        one_list.push(item.profit_total);//总利润（元）

                        two_list.push(item.user_total);//客户总发送量

                        three_list.push(item.cpid_success_num);//客户成功数
                        four_list.push(item.cpid_fail_num);//客户失败数
                        five_list.push(item.cpid_unknown_num);//客户未知数

                        six_list.push(item.repeat_num);//重发数

                        seven_list.push(item.check_total);//审核量

                        eight_list.push(item.profit_average_one);//平均单条利润(厘)

                        nine_list.push(item.cpid_success_rate);//客户成功率
                        ten_list.push(item.cpid_fail_rate);//客户失败率
                        eleven_list.push(item.cpid_unknown_rate);//客户未知率
                        twelve_list.push(item.repeat_rate);//重发率

                    }

                    dataList.push(one_list);
                    dataList.push(two_list);
                    dataList.push(three_list);
                    dataList.push(four_list);
                    dataList.push(five_list);
                    dataList.push(six_list);
                    dataList.push(seven_list);
                    dataList.push(eight_list);
                    dataList.push(nine_list);
                    dataList.push(ten_list);
                    dataList.push(eleven_list);
                    dataList.push(twelve_list);

                    //图表
                    this.imageTable(xAxisData,dataList,titleList,"image_table_id");
                } 
            });
        },

        //自定义合计列
        getSummers(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = '合计';
                return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (column.property === 'profit_total' || column.property === 'check_total'
                 || column.property === 'user_total' || column.property === 'cpid_success_num'
                 || column.property === 'cpid_fail_num' || column.property === 'cpid_unknown_num'
                 || column.property === 'repeat_num'
                 
                 ) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Math.round((prev + curr)*1000)/1000;
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index];
                } else {
                    sums[index] = '--';
                }
            });
        
            return sums;

        },


        //图表
        imageTable(xAxisData,dataList,titleList,div_id){
            // var id = "image_table_id";
            var id = div_id;

            // var xAxisData = [];
            // var yAxisData = [];
            // for(var i = 0 ;i<dataList.length;i++){
            //     var dataObj = dataList[i];
                
            //     xAxisData.push(dataObj.daytime);
            //     yAxisData.push(dataObj.success_num);
            // }
            // console.log(xAxisData);
            // console.log(yAxisData);
            var series_array = [];
            for(var i = 0;i<dataList.length;i++){
                var val = dataList[i];
                var series_obj = {
                    // itemStyle: {
                    //     color: '#67C23A'//线条颜色
                    // },
                    smooth: true, //线条平滑
                    type:'line'
                };
                series_obj.name = titleList[i];
                series_obj.data = val;
                series_array.push(series_obj);
            }

            var option = {
                //位置调整
                // grid:{
                //     left: '75',
                //     top: '15',//15
                //     right: '35',
                //     bottom: '30'
                // },
                title: {
                    // text: '价格列表'
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:titleList
//                        data:['总发送量','dsadsa','成功','dsadsadas']
                },
                toolbox: {
                    show : true,
                    feature : {
                        saveAsImage : {show: true}
                    }
                },
                // calculable : true,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    // data: ['2021-12-01', '2021-12-02', '2021-12-03', '2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07'],
                    data : xAxisData,
                    // axisLabel: {
                    //     color: "#909399",  //坐标轴线的字体颜色
                    //     fontSize: "12",   //坐标轴线的字体大小
                    // },
                    // axisLine:{ 
                    //     lineStyle:{  
                    //         color: "#67C23A",  //坐标轴线的颜色
                    //         width: "1",
                    //         type: "solid",
                            
                    //     },
                    // },
                },
                yAxis: {
                    type: 'value',
                    // axisLabel: {
                    //     formatter: '{value} '
                    // },
                    // axisLabel: {
                    //     color: "#909399",  //刻度线标签的字体颜色
                    //     fontSize: "14",   //坐标轴线的字体大小
                    // },
                    // axisLine:{ 
                    //     lineStyle:{  
                    //         color: "#05f9f9",  //坐标轴线的颜色
                    //         width: "1",
                    //         type: "solid",
                            
                    //     },
                    // },
                },
                series: series_array
            };  
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);

            // myChart.on('click', function(params) {
                
            //     console.log("图表点击事件："+params.name);
            //     console.log(params);
            // });
        },

        

        //返回
        goBack() {
            API.router_to("/operation_index");
        }
    }
};

</script>

<style scoped>


</style>